/**
 * Emit custom event
 *
 * @param {string} name
 * @param {object} config
 */
export function emit(name, config = {}) {
  const customEvent = new CustomEvent(`label-${name}`, config);
  window.dispatchEvent(customEvent);
}

/**
 * Subscribe custom event
 *
 * @param {*} name
 * @param {*} cb callback
 * @returns {object} unsubscribe method
 */
export function subscribe(name, cb) {
  window.addEventListener(`label-${name}`, cb);

  return {
    unsubscribe: () => {
      window.removeEventListener(`tipply-${name}`, cb);
    },
  };
}
