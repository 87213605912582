import { Fireworks } from '@fireworks-js/react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const EndFireworks = ({ running = false }) => {
  const fireworksRef = useRef();

  useEffect(() => {
    if (fireworksRef.current) {
      fireworksRef.current.stop();
    }
  }, [fireworksRef]);

  useEffect(() => {
    if (fireworksRef.current) {
      if (running) {
        fireworksRef.current.start();
        setTimeout(() => {
          if (fireworksRef.current) {
            fireworksRef.current.waitStop();
          }
        }, 5000);
      }
    }
  }, [fireworksRef, running]);

  return (
    <Fireworks
      ref={fireworksRef}
      options={{
        rocketsPoint: {
          min: 10,
          max: 90,
        },
      }}
      style={{
        top: 50,
        left: 50,
        right: 50,
        bottom: 50,
        position: 'absolute',
      }}
    />
  );
};

EndFireworks.propTypes = {
  running: PropTypes.bool.isRequired,
};

export default EndFireworks;
