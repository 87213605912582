/**
 * Akcja usuwająca pierwszą wiadomość z kolejki
 *
 * @returns {Object}
 */
export const clearLatestMessage = () => ({
  type: 'CLEAR_LATEST_MESSAGE',
});

/**
 * Akcja umieszczająca wiadomość do kolejki
 *
 * @param {Object} message
 * @param {Function} ack
 * @returns {Object}
 */
export const insertMessage = (message, ack) => ({
  type: 'INSERT_MESSAGE',
  message,
  ack,
});
