import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  40% {
    opacity: 0.5;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateY(-250%);
  }
`;

export default styled.span`
  position: absolute;
  opacity: 0;
  transform: translateY(0%);

  animation: ${animation} 1s ease;
  animation-fill-mode: forwards;
`;
