import styled from 'styled-components';

import ParentScene from 'styles/Scene';

const Scene = styled(ParentScene)`
  width: 800px;
  height: 600px;
  position: relative;
`;

export default Scene;
