import { apiUrl } from 'utils/http';

/**
 * @param {string} path
 * @param {boolean} isDefault
 * @returns {string}
 */
export const crateUrl = (path, isDefault) => `${isDefault ? '' : apiUrl}${path}`;

/**
 * @param {string} mediumId
 * @param {object[]} media
 */
export const getUrl = (mediumId, media) => {
  if (!mediumId && !media[mediumId]) return '';

  return {
    type: media[mediumId].reference.provider,
    url: crateUrl(media[mediumId].reference.url, media[mediumId].default),
  };
};
