import styled from 'styled-components';

const GoalWrapper = styled.div`
  position: relative;
  background-color: ${props => props.color};
  width: ${props => props.width}%;
  overflow: hidden;
  transition: all 600ms ease-in-out;
  text-align: center;

  > div {
    //padding: 5px 10px;
  }
`;

export default GoalWrapper;
