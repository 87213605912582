import io from 'socket.io-client';

/**
 * Funkcja uruchamiająca socket powiadomień
 *
 * @param {function} alertCallback
 * @returns {object}
 */
export default function alertSocket(alertCallback = () => {}) {
  const [, userId] = new URL(window.location.href).pathname.split('/').filter(chunk => !!chunk);

  const instance = io(`${process.env.REACT_APP_ALERT_SOCKET_URL}/${userId}`);

  return {
    on() {
      instance.on('alert', alertCallback);
    },
    off() {
      instance.off('alert', alertCallback);
    },
  };
}
