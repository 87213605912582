import React, { useContext, useEffect, useState } from 'react';

import { Time } from 'components/elements';
import EndFireworks from 'components/elements/EndFireworks';

import Context from '../../../../context';

import TextNote from './components/TextNote';
import Wrapper from './components/Wrapper';

const Timer = () => {
  const { template, finishDate, finished } = useContext(Context);
  const [fireworks, setFireworks] = useState(false);

  const {
    config: {
      options: { align, reverse, showTte },
    },
  } = template;

  useEffect(() => {
    if (finishDate && finished) {
      setFireworks(true);
    }
  }, [finished, finishDate]);

  return (
    <Wrapper align={align} reverse={reverse}>
      {showTte && finishDate && !finished && (
        <>
          <Time
            mode="COUNTER"
            options={{ isVisible: true, isAnimated: true }}
            time={new Date(finishDate)}
          />
          <TextNote>
            Czas do końca
            <br />
            głosowania
          </TextNote>
        </>
      )}
      {showTte && finishDate && finished && <TextNote>Głosowanie zakończone!</TextNote>}
      {showTte && !finishDate && <TextNote>Ustaw datę i czas zakończenia głosowania</TextNote>}
      <EndFireworks running={fireworks} />
    </Wrapper>
  );
};

export default Timer;
