import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const DaysWrapper = styled.div``;

export const DayOdometerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 0.5em;
  gap: 8px;
  transform: translateY(-90%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${DaysWrapper} {
    transform: translateY(-3px);
  }
`;

export const Days = ({ days }) => {
  const variants = {
    one: 'dzień',
    few: 'dni',
    many: 'dni',
    other: 'dni',
  };

  const [word, setWord] = useState(variants.one);

  useEffect(() => {
    setWord(variants[new Intl.PluralRules('pl-PL').select(days)]);
  }, [days]);

  return <DaysWrapper>{` ${word} `}</DaysWrapper>;
};

Days.propTypes = {
  days: PropTypes.number.isRequired,
};
