import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Element from 'styles/Element';
import extractElementStyles from 'utils/extractElementStyles';

const Text = ({ options, animation, className = '' }) =>
  options.isVisible && (
    <Element
      style={{ ...extractElementStyles(options) }}
      className={`${cx(animation)} ${className}`}
    >
      {options.textValue}
    </Element>
  );

Text.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  animation: PropTypes.string,
  className: PropTypes.string,
};

Text.defaultProps = {
  animation: '',
  className: '',
};

export default Text;
