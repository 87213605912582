import styled, { keyframes, css } from 'styled-components';

const stripesAnimation = keyframes`
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
`;

const stripesStyles = css`
  background-image: linear-gradient(
    45deg,
    var(--stripesColor) 25%,
    transparent 25%,
    transparent 50%,
    var(--stripesColor) 50%,
    var(--stripesColor) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  animation: ${stripesAnimation} 2s linear infinite;
`;

const StyledProgressBar = styled.div`
  --stripesColor: ${props => (props.isLight ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)')};

  width: 100%;
  height: 100%;
  overflow: hidden;

  > div {
    width: 10%;
    height: 100%;
    transition: width 14s cubic-bezier(0.22, 1, 0.36, 1);

    ${props => props.stripes && stripesStyles}
  }
`;

export default StyledProgressBar;
