export default {
  configurations: {
    isFetching: false,
    isFetched: false,

    items: [],
  },

  templates: {
    isFetching: false,
    isFetched: false,

    items: [],
  },

  media: {
    isFetching: false,
    isFetched: false,

    items: {},
  },
  widgetmessages: {
    isFetching: false,
    isFetched: false,
    disabled: true,
  },

  tipsMessages: [],
};
