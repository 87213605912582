import { mediaUrl } from 'utils/http';

/**
 * Tworzy URL dla medium
 *
 * @param {string} filePath
 * @param {boolean} [isDefault]
 * @return {string}
 */
export default function createMediumURL(filePath, isDefault) {
  return `${isDefault ? '' : mediaUrl}${filePath}`;
}
