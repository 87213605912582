export default [
  'visualObject',
  'price',
  'username',
  'usernameAction',
  'message',
  'visualObject1',
  'visualObject2',
  'visualObject3',
  'usernameAction1',
  'usernameAction2',
  'usernameAction3',
];
