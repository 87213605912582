import * as Sentry from '@sentry/react';

import store from 'store';
import { fetchMedia } from 'store/actions/media';
import createMediumURL from 'utils/createMediumURL';
import hop from './hop';

/**
 * Funkcja uruchamiająca wszystkie sockety dla napiwków, konfiguracji oraz szablonów.
 *
 * @param {string} configuratorType
 * @param {string} currentTemplateId
 * @returns {object}
 */
export default async function fetchUsedMediaInTipAlertView(configuratorType, currentTemplateId) {
  const {
    configurations: { items: configurationsItems },
    templates: { items: templatesItems },
  } = store.getState();

  const extractMediaIdsFromDisplaySetings = displaySettings => {
    const mediaIds = [];
    mediaIds.push(displaySettings.defaults.sounds.mediumId);

    displaySettings.tresholds.sounds.forEach(sound => {
      mediaIds.push(sound.mediumId);
    });

    return mediaIds.filter(mediumId => !!mediumId);
  };

  const extractMediaIdsFromTemplates = templates => {
    const mediaIds = [];

    templates.forEach(template => {
      if (hop(template.config.elementsOptions, 'visualObject')) {
        mediaIds.push(template.config.elementsOptions.visualObject.mediumId);
      }
      if (hop(template.config.elementsOptions, 'visualObject1')) {
        mediaIds.push(template.config.elementsOptions.visualObject1.mediumId);
      }
      if (hop(template.config.elementsOptions, 'visualObject2')) {
        mediaIds.push(template.config.elementsOptions.visualObject2.mediumId);
      }
      if (hop(template.config.elementsOptions, 'visualObject3')) {
        mediaIds.push(template.config.elementsOptions.visualObject3.mediumId);
      }
    });

    return mediaIds.filter(mediumId => !!mediumId);
  };

  const extractTemplatesIdsFromDisplaySetings = displaySettings => {
    const templatesIds = [];
    templatesIds.push(displaySettings.defaults.templates.templateId);

    displaySettings.tresholds.templates.forEach(templates => {
      templatesIds.push(templates.templateId);
    });

    return templatesIds.filter(templateId => !!templateId);
  };

  /**
   * Funkcja przeładowująca wszystkie media
   *
   * @param {Object} mediaObj
   * @returns {Promise[]}
   */
  const preloadMedia = mediaObj => {
    const promises = [];

    for (const mediumId in mediaObj) {
      if (Object.prototype.hasOwnProperty.call(mediaObj, mediumId)) {
        const mediumObject = mediaObj[mediumId];
        const mediumUrl = createMediumURL(mediumObject.reference.url, mediumObject.default);
        let promise;

        if (mediumUrl) {
          promise = new Promise((resolve, reject) => {
            const type = mediumObject.reference.provider;

            switch (type) {
              case 'sonata.media.provider.image': {
                const img = new Image();
                img.addEventListener('load', () => resolve());
                img.addEventListener('error', e => reject(e));
                img.src = mediumUrl;
                break;
              }
              case 'sonata.media.provider.user_image': {
                const img = new Image();
                img.addEventListener('load', () => resolve());
                img.addEventListener('error', e => reject(e));
                img.src = mediumUrl;
                break;
              }
              case 'sonata.media.provider.user_audio': {
                const audio = new Audio(mediumUrl);
                audio.autoplay = false;
                audio.addEventListener('canplaythrough', () => resolve());
                audio.addEventListener('error', e => reject(e));
                audio.load();
                break;
              }
              case 'sonata.media.provider.user_video': {
                const video = document.createElement('video');
                video.addEventListener('canplaythrough', () => resolve());
                video.addEventListener('error', e => reject(e));
                video.src = mediumUrl;
                video.load();
                break;
              }
              default:
                resolve();
            }
          });
        }

        if (promise) {
          promises.push(promise);
        }
      }
    }

    return promises;
  };

  return new Promise(async (resolve, reject) => {
    const configuration = configurationsItems.find(item => item.type === configuratorType);
    let templatesIds = [];
    let configurationMediaIds = [];

    if (configuratorType === 'TIP_ALERT') {
      configurationMediaIds = extractMediaIdsFromDisplaySetings(
        configuration.config.displaySettings,
      );

      templatesIds = extractTemplatesIdsFromDisplaySetings(configuration.config.displaySettings);
    } else {
      templatesIds = [currentTemplateId];
    }

    const filteredTemplates = templatesItems.filter(template => templatesIds.includes(template.id));
    const mediaIdsTemplates = extractMediaIdsFromTemplates(filteredTemplates);

    const media = await store.dispatch(
      fetchMedia([...new Set(mediaIdsTemplates.concat(configurationMediaIds))]),
    );

    try {
      await Promise.all(preloadMedia(media));
      resolve();
    } catch (error) {
      Sentry.setExtra('error', error);
      Sentry.captureException(error);
      reject(error);
    }
  });
}
