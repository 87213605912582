import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import extractElementStyles from 'utils/extractElementStyles';
import { selectMedia } from 'store/selectors';
import { getUrl } from 'store/utils/media';

const VisualObject = ({ options, animation, className = '' }) => {
  if (!options.isVisible) {
    return null;
  }

  const media = useSelector(selectMedia);
  const medium = media.items[options.mediumId];

  if (!medium) {
    return null;
  }

  const { url, type } = getUrl(options.mediumId, media.items);

  if (type === 'sonata.media.provider.image' || type === 'sonata.media.provider.user_image') {
    return (
      <img
        style={{ ...extractElementStyles(options) }}
        className={`${cx(animation)} ${className}`}
        src={url}
        alt=""
      />
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      src={url}
      autoPlay
      muted
      style={{ ...extractElementStyles(options) }}
      className={`${cx(animation)} ${className}`}
    />
  );
};

VisualObject.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  animation: PropTypes.string,
  className: PropTypes.string,
};

VisualObject.defaultProps = {
  animation: '',
  className: '',
};

export default VisualObject;
