const templates = [
  {
    title: 'Domyślny szablon #1',
    editable: true,
    animation: {
      enable: false,
      duration: 0,
      durationMultiply: 5,
    },
    elementsOptions: {
      list: {
        children: {
          colon: {
            styles: {
              color: '#fafafa',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          price: {
            styles: {
              color: '#0dfa00',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          counter: {
            styles: {
              color: '#ffffff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          nickname: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
        },
        position: {
          x: 23,
          y: 19,
        },
      },
    },
    displayDirection: 'vertical',
    showDonatesFromDate: {
      type: 'ALL',
      value: null,
    },
    numberDisplayedItems: 5,
    spacingBetweenElements: 5,
    amountWithoutCommission: true,
  },
  {
    title: 'Domyślny szablon #2',
    editable: true,
    animation: {
      enable: true,
      duration: 0,
      durationMultiply: 5,
    },
    elementsOptions: {
      list: {
        children: {
          colon: {
            styles: {
              color: '#fafafa',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          price: {
            styles: {
              color: '#8e00ff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          counter: {
            styles: {
              color: '#ffffff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          nickname: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
        },
        position: {
          x: 26,
          y: 18,
        },
      },
    },
    displayDirection: 'horizontal',
    showDonatesFromDate: {
      type: 'ALL',
      value: null,
    },
    numberDisplayedItems: 10,
    spacingBetweenElements: 45,
    amountWithoutCommission: true,
  },
  {
    title: 'Ranking dnia',
    editable: true,
    animation: {
      enable: false,
      duration: 0,
      durationMultiply: 5,
    },
    elementsOptions: {
      list: {
        children: {
          colon: {
            styles: {
              color: '#fafafa',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          price: {
            styles: {
              color: '#0dfa00',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          counter: {
            styles: {
              color: '#ffffff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          nickname: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
        },
        position: {
          x: 23,
          y: 19,
        },
      },
    },
    displayDirection: 'vertical',
    showDonatesFromDate: {
      type: 'LAST_DAY',
      value: null,
    },
    numberDisplayedItems: 3,
    spacingBetweenElements: 5,
    amountWithoutCommission: true,
  },
  {
    title: 'Ranking tygodnia',
    editable: true,
    animation: {
      enable: false,
      duration: 0,
      durationMultiply: 5,
    },
    elementsOptions: {
      list: {
        children: {
          colon: {
            styles: {
              color: '#fafafa',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          price: {
            styles: {
              color: '#f2ff00',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          counter: {
            styles: {
              color: '#ffffff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          nickname: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
        },
        position: {
          x: 23,
          y: 19,
        },
      },
    },
    displayDirection: 'vertical',
    showDonatesFromDate: {
      type: 'LAST_WEEK',
      value: null,
    },
    numberDisplayedItems: 3,
    spacingBetweenElements: 5,
    amountWithoutCommission: true,
  },
  {
    title: 'Ranking miesiąca',
    editable: true,
    animation: {
      enable: false,
      duration: 0,
      durationMultiply: 5,
    },
    elementsOptions: {
      list: {
        children: {
          colon: {
            styles: {
              color: '#fafafa',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          price: {
            styles: {
              color: '#ff00bf',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          counter: {
            styles: {
              color: '#ffffff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          nickname: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Lato',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
        },
        position: {
          x: 23,
          y: 19,
        },
      },
    },
    displayDirection: 'vertical',
    showDonatesFromDate: {
      type: 'LAST_MONTH',
      value: null,
    },
    numberDisplayedItems: 3,
    spacingBetweenElements: 5,
    amountWithoutCommission: true,
  },
];

export default templates;
