import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';

import pulsing from 'styles/base/animations';

export const Container = styled.span`
  display: inline-block;
  white-space: nowrap;
  ${props => props.pulsing && pulsing}
`;

const CountUpNumber = React.forwardRef(({ end, withPulsing, style, ...props }, ref) => {
  const [shouldPulsing, setShouldPulsing] = useState(false);

  const easing = (t, b, c, d) => {
    const x = t / d - 1;
    return c * (x * x * x * x * x + 1) + b;
  };

  const { countUp, update } = useCountUp({
    ...props,
    start: end,
    end,
    useEasing: true,
    duration: 14,
    easingFn: easing,
    onEnd: () => {
      setShouldPulsing(false);
    },
  });

  useEffect(() => {
    update(end);
    setShouldPulsing(true);
  }, [end]);

  return (
    <Container ref={ref} pulsing={withPulsing ? shouldPulsing : false} style={style}>
      {countUp}
    </Container>
  );
});

CountUpNumber.propTypes = {
  end: PropTypes.number.isRequired,
  withPulsing: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
};

CountUpNumber.defaultProps = {
  withPulsing: false,
  style: {},
};

export default CountUpNumber;
