import React, { useContext, useEffect, useState } from 'react';

import Context from '../../context';

import Bar from './components/Bar';
import InnerWrapper from './components/InnerWrapper';
import Legend from './components/Legend';
import ScreenError from './components/ScreenError';
import Timer from './components/Timer';
import Wrapper from './components/Wrapper';

const ScreenElements = () => {
  const { goals, finished, finishDate } = useContext(Context);
  const [definitiveFinish, setDefinitiveFinish] = useState(false);

  const isVotingExpired = () => (new Date() - new Date(finishDate)) / 1000 > 5 * 60;

  useEffect(() => {
    if (finishDate && finished) {
      setDefinitiveFinish(isVotingExpired());
    }
  }, [finished, finishDate]);

  useEffect(() => {
    const checkIsFinishInterval = setInterval(() => {
      setDefinitiveFinish(isVotingExpired());
    }, 1000);

    return () => {
      clearInterval(checkIsFinishInterval);
    };
  }, [finishDate]);

  return (
    <Wrapper>
      {definitiveFinish ? (
        <ScreenError
          message={'Głosowanie zakończyło się. Zmień datę "do końca" by wznowić głosowanie.'}
        />
      ) : (
        <>
          {goals.length >= 2 ? (
            <InnerWrapper>
              <Legend />
              <Bar />
              <Timer />
            </InnerWrapper>
          ) : (
            <ScreenError message="Aby rozpocząć wybierz cele do głosowania" />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ScreenElements;
export { useVotingGoals } from './components/hooks';
