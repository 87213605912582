import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import * as Sentry from '@sentry/react';

import CountUpNumber from 'components/CountUpNumber';
import CustomCode from 'components/CustomCode';
import ErrorMessage from 'components/ErrorMessage';
import WithData from 'containers/WithData';

import priceFormatter from 'utils/priceFormatter';
import extractElementStyles from 'utils/extractElementStyles';
import http from 'utils/http';
import sockets from 'utils/sockets';

import Element from 'styles/Element';

import Scene from './styles/Scene';
import ProgressBar from './styles/ProgressBar';
import AnimatedPrice from './styles/AnimatedPrice';
import ElementsGroup from './styles/ElementsGroup';
import ElementsGroupWrapper from './styles/ElementsGroupWrapper';

const calculateBorderRadius = (radius, elementHeight) => ((elementHeight / 2) * radius) / 100;

class TipsGoal extends Component {
  static propTypes = {
    templates: PropTypes.instanceOf(Array).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        goalId: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentValues: {
        amount: 0,
        commission: 0,
      },
      animatedNumber: null,
      isError: false,
      isNotFoundError: false,
      goal: null,
    };

    this.sockets = sockets(
      this.handleReceiveTipSocket,
      () => {},
      () => {},
      this.handleGoalsConfigurationUpdated,
    );
  }

  componentDidMount() {
    this.sockets.on();
    (async () => {
      await this.getGoalFromAPI();
    })();
  }

  componentWillUnmount() {
    this.sockets.off();
  }

  getGoalFromAPI = async () => {
    const { match } = this.props;

    try {
      const { data } = await http.get(`/widget/goal/${match.params.goalId}/${match.params.userId}`);
      const { stats: currentValues, config: goal } = data;

      this.setState({
        currentValues,
        goal,
      });
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({
          isNotFoundError: true,
        });
      } else {
        Sentry.setExtra('error', error);
        Sentry.captureException(error);
      }
    }
  };

  getCurrentTemplate() {
    const { goal } = this.state;
    const { templates } = this.props;

    return templates.find(template => template.id === goal.template_id);
  }

  /**
   * Funkcja odbierająca obiekt z socketa i umieszczająca go do store
   *
   * @param {Object} message
   * @returns {void}
   */
  handleReceiveTipSocket = message => {
    const { goal } = this.state;

    if (message.goal_id !== goal.id) {
      return;
    }

    setTimeout(async () => {
      await this.getGoalFromAPI();

      const price = goal.without_commission
        ? message.amount - message.commission || 0
        : message.amount;

      this.setState({
        animatedNumber: price,
      });
    }, 500);
  };

  /**
   * Funkcja jest wywoływana w momencie zmiany w konfiguracji celów
   */
  handleGoalsConfigurationUpdated = goalId => {
    const { goal } = this.state;

    if (goalId !== goal.id) {
      return;
    }

    setTimeout(async () => {
      await this.getGoalFromAPI();

      const {
        currentValues: { amount, commission },
      } = this.state;

      const price = goal.without_commission ? amount - commission || 0 : amount;

      this.setState({
        animatedNumber: price || null,
      });
    }, 500);
  };

  /**
   * Funkcja odpalająca się po animacji wpłaconej kwoty
   *
   * @returns {void}
   */
  handleAnimationEnd = () => {
    this.setState({
      animatedNumber: null,
    });
  };

  /**
   * Zwraca procentową wartość uzbieranej kwoty
   *
   * @param {number} from
   * @param {number} to
   * @returns {number}
   */
  calculateGoalPercent = (from, to) => (to === 0 ? 0 : ((from * 100) / to).toFixed(2));

  /**
   * Funkcja sprawdzajaca czy komponent ma wyrenderować zawartość sceny
   * Sprawdzane są przypadki czy został ustawiony odpowiedni szablon
   *
   * @returns {boolean}
   */
  shouldRender() {
    const { goal } = this.state;

    return !!goal;
  }

  renderElements(elementsOptions, percentValue, currentPrice, shadow = false) {
    const { goal } = this.state;

    let fromX = 0;
    let fromY = 0;
    let separatorX = 0;
    let separatorY = 0;
    let toX = 0;
    let toY = 0;
    let percentX = 0;
    let percentY = 0;
    let positionStyles = {};
    const startPositionX = elementsOptions.goalNumbers.position.x;

    if (elementsOptions.goalNumbers.changePosition) {
      fromX = elementsOptions.goalNumbers.children.from.position
        ? elementsOptions.goalNumbers.children.from.position.x
        : startPositionX;
      fromY = elementsOptions.goalNumbers.children.from.position
        ? elementsOptions.goalNumbers.children.from.position.y
        : elementsOptions.goalNumbers.position.y;
      separatorX = elementsOptions.goalNumbers.children.separator.position
        ? elementsOptions.goalNumbers.children.separator.position.x
        : startPositionX + 170;
      separatorY = elementsOptions.goalNumbers.children.separator.position
        ? elementsOptions.goalNumbers.children.separator.position.y
        : elementsOptions.goalNumbers.position.y;
      toX = elementsOptions.goalNumbers.children.to.position
        ? elementsOptions.goalNumbers.children.to.position.x
        : startPositionX + 200;
      toY = elementsOptions.goalNumbers.children.to.position
        ? elementsOptions.goalNumbers.children.to.position.y
        : elementsOptions.goalNumbers.position.y;
      percentX = elementsOptions.goalNumbers.children.percent.position
        ? elementsOptions.goalNumbers.children.percent.position.x
        : startPositionX + 350;
      percentY = elementsOptions.goalNumbers.children.percent.position
        ? elementsOptions.goalNumbers.children.percent.position.y
        : elementsOptions.goalNumbers.position.y;
      positionStyles = {
        position: 'absolute',
        left: '0px',
        top: '0px',
      };
    }

    return (
      <>
        <ElementsGroupWrapper shadow={shadow}>
          {elementsOptions.goalNumbers.children.from.isVisible && (
            <Element
              shadow={shadow}
              style={{
                margin: '0 5px',
                ...extractElementStyles(elementsOptions.goalNumbers.children.from),
                ...positionStyles,
                transform: `translate(${fromX}px, ${fromY}px)`,
              }}
              className="tpl-amount-value"
            >
              {shadow ? (
                '10 000,00 zł'
              ) : (
                <CountUpNumber
                  end={currentPrice / 100}
                  decimals={2}
                  decimal=","
                  separator=" "
                  suffix=" zł"
                  withPulsing
                />
              )}
            </Element>
          )}
          {elementsOptions.goalNumbers.children.separator.isVisible && (
            <Element
              shadow={shadow}
              style={{
                margin: '0 5px',
                ...extractElementStyles(elementsOptions.goalNumbers.children.separator),
                ...positionStyles,
                transform: `translate(${separatorX}px, ${separatorY}px)`,
              }}
              className="tpl-separator"
            >
              {elementsOptions.goalNumbers.children.separator.textValue}
            </Element>
          )}

          {elementsOptions.goalNumbers.children.to.isVisible && (
            <Element
              shadow={shadow}
              style={{
                margin: '0 5px',
                ...extractElementStyles(elementsOptions.goalNumbers.children.to),
                ...positionStyles,
                transform: `translate(${toX}px, ${toY}px)`,
              }}
              className="tpl-amount-target"
            >
              {shadow ? '10 000,00 zł' : priceFormatter(goal.target)}
            </Element>
          )}
          {elementsOptions.goalNumbers.children.percent.isVisible && (
            <Element
              shadow={shadow}
              style={{
                margin: '0 5px',
                ...extractElementStyles(elementsOptions.goalNumbers.children.percent),
                ...positionStyles,
                transform: `translate(${percentX}px, ${percentY}px)`,
              }}
              className="tpl-amount-percent"
            >
              {shadow ? (
                `(99.99%)`
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {'('}
                  <CountUpNumber
                    end={parseFloat(percentValue)}
                    decimals={2}
                    decimal="."
                    suffix="%"
                  />
                  {')'}
                </div>
              )}
            </Element>
          )}
        </ElementsGroupWrapper>
      </>
    );
  }

  render() {
    const { isNotFoundError, isError } = this.state;

    if (isError) {
      return <ErrorMessage />;
    }

    if (isNotFoundError) {
      return (
        <ErrorMessage
          message="Przepraszamy, taki cel nie istnieje. Sprawdź adres widgetu."
          defaultError={false}
        />
      );
    }

    if (!this.shouldRender()) return null;

    const { currentValues, animatedNumber, goal } = this.state;

    const {
      config: { elementsOptions },
    } = this.getCurrentTemplate();

    const baseValue = goal.initial_value;
    const currentPrice = goal.without_commission
      ? currentValues.amount - currentValues.commission + baseValue
      : currentValues.amount + baseValue;

    const percentValue = this.calculateGoalPercent(currentPrice, goal.target);

    return (
      <Scene>
        {elementsOptions.goalName.isVisible && (
          <span style={{ ...extractElementStyles(elementsOptions.goalName) }}>{goal.title}</span>
        )}

        {elementsOptions.amountPaid.isVisible && animatedNumber && (
          <AnimatedPrice
            style={{ ...extractElementStyles(elementsOptions.amountPaid) }}
            onAnimationEnd={this.handleAnimationEnd}
            className="tpl-animated-price"
          >
            <Element shadow>1 000 000,00 zł</Element>
            {priceFormatter(animatedNumber)}
          </AnimatedPrice>
        )}

        {elementsOptions.progressBar.isVisible && (
          <ProgressBar
            stripes={elementsOptions.progressBar.stripes}
            isLight={tinycolor(elementsOptions.progressBar.progressColor).isLight()}
            style={{
              ...extractElementStyles(elementsOptions.progressBar),
              borderRadius: calculateBorderRadius(
                elementsOptions.progressBar.size.height,
                elementsOptions.progressBar.styles.borderRadius,
              ),
              backgroundImage: `linear-gradient(${elementsOptions.progressBar.styles.backgroundColor}, ${elementsOptions.progressBar.styles.gradientColor})`,
            }}
            className="tpl-progress-bar"
          >
            {elementsOptions.progressBar.stripes && (
              <div
                className="tpl-animated-price-fill-part"
                style={{
                  width: `${percentValue}%`,
                  backgroundColor: elementsOptions.progressBar.progressColor,
                }}
              />
            )}
            {!elementsOptions.progressBar.stripes && (
              <div
                className="tpl-animated-price-fill-part"
                style={{
                  width: `${percentValue}%`,
                  backgroundColor: elementsOptions.progressBar.progressColor,
                  backgroundImage: `linear-gradient(${elementsOptions.progressBar.progressColor}, ${elementsOptions.progressBar.gradientColor})`,
                }}
              />
            )}
          </ProgressBar>
        )}

        {elementsOptions.goalNumbers.changePosition && (
          <ElementsGroup>
            {this.renderElements(elementsOptions, percentValue, currentPrice, true)}
            {this.renderElements(elementsOptions, percentValue, currentPrice)}
          </ElementsGroup>
        )}
        {!elementsOptions.goalNumbers.changePosition && (
          <ElementsGroup style={{ ...extractElementStyles(elementsOptions.goalNumbers) }}>
            {this.renderElements(elementsOptions, percentValue, currentPrice, true)}
            {this.renderElements(elementsOptions, percentValue, currentPrice)}
          </ElementsGroup>
        )}

        <CustomCode template={this.getCurrentTemplate()} />
      </Scene>
    );
  }
}

export default withRouter(WithData(TipsGoal));
