import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import Stripes from 'styles/Stripes';
import CountUpNumber from 'components/CountUpNumber';

import Context from '../../../../../context';
import GoalWrapper from './GoalWrapper';

const valueStyle = css`
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  position: absolute;
  left: 15px;
  right: 15px;

  > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const bigVariant = css`
  ${valueStyle};
  font-size: 20.83px;
  top: 35px;
`;

const smallVariant = css`
  ${valueStyle};
  font-size: 12.5px;
  color: rgba(255, 255, 255, 0.7);
  top: 19px;
`;

const ValuesContainer = styled.div`
  z-index: 100;
`;

const Value = styled.span`
  ${props => (props.big ? bigVariant : smallVariant)};
`;

const Goal = ({ goal: { goal, color, stats }, percentage, isWinner, finished, isEmpty }) => {
  const isSmallPercentage = () => percentage <= 15;
  const goalValue = () => (goal.initial_value + stats.amount - stats.commission) / 100;

  const { template } = useContext(Context);
  const bigValueRef = useRef();
  const smallValueRef = useRef();
  const bigPercentageRef = useRef();
  const smallPercentageRef = useRef();
  const [value, setValue] = useState(() => goalValue());
  const [isSmall, setIsSmall] = useState(() => isSmallPercentage());

  useEffect(() => {
    setValue(goalValue());
  }, [goal.initial_value, stats.amount, stats.commission]);

  useEffect(() => {
    setIsSmall(isSmallPercentage());
  }, [percentage]);

  const {
    config: {
      options: { showValues, barAnimation, biggerPercentage, showPercents },
    },
  } = template;

  const width = () => {
    if (finished) {
      return isWinner ? 100 : 0;
    }
    return percentage;
  };

  const initialStyle = {
    transition: 'opacity 500ms ease-out',
    opacity: 0,
  };

  const transitions = {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
    },
    exiting: {
      opacity: 1,
    },
    exited: {
      opacity: 0,
    },
  };

  if (percentage === 0) return null;

  return (
    <GoalWrapper color={color} width={width()} className="tpl-progress-bar-goal">
      <div>
        {barAnimation && isWinner && <Stripes color={color} />}
        {percentage > 7 && (
          <ValuesContainer>
            {showValues && (
              <Value big={!biggerPercentage} className="tpl-progress-bar-goal-value">
                <Transition in={isSmall && value >= 1000} nodeRef={smallValueRef}>
                  {state => (
                    <CountUpNumber
                      ref={smallValueRef}
                      end={value / 1000}
                      decimals={1}
                      decimal=","
                      separator=""
                      suffix="K"
                      withPulsing
                      style={{
                        ...initialStyle,
                        ...transitions[state],
                      }}
                    />
                  )}
                </Transition>
                <Transition in={!isSmall || value < 1000} nodeRef={bigValueRef}>
                  {state => (
                    <CountUpNumber
                      ref={bigValueRef}
                      end={value}
                      decimals={2}
                      decimal=","
                      separator=" "
                      suffix=" zł"
                      withPulsing
                      style={{
                        ...initialStyle,
                        ...transitions[state],
                      }}
                    />
                  )}
                </Transition>
              </Value>
            )}
            {showPercents && (
              <Value big={biggerPercentage} className="tpl-progress-bar-goal-value">
                <Transition in={isSmall} nodeRef={smallPercentageRef}>
                  {state => (
                    <CountUpNumber
                      ref={smallPercentageRef}
                      end={isEmpty ? 0 : percentage}
                      decimals={0}
                      suffix="%"
                      withPulsing
                      style={{
                        ...initialStyle,
                        ...transitions[state],
                      }}
                    />
                  )}
                </Transition>
                <Transition in={!isSmall} nodeRef={bigPercentageRef}>
                  {state => (
                    <CountUpNumber
                      ref={bigPercentageRef}
                      end={isEmpty ? 0 : percentage}
                      decimals={2}
                      decimal=","
                      suffix="%"
                      withPulsing
                      style={{
                        ...initialStyle,
                        ...transitions[state],
                      }}
                    />
                  )}
                </Transition>
              </Value>
            )}
          </ValuesContainer>
        )}
      </div>
    </GoalWrapper>
  );
};

Goal.propTypes = {
  goal: PropTypes.instanceOf(Object).isRequired,
  percentage: PropTypes.number.isRequired,
  isWinner: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default Goal;
