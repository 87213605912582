import styled from 'styled-components';

const InnerWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 76px 1fr;
  gap: 15px;
  height: 100%;
  width: 75%;
`;

export default InnerWrapper;
