// TODO: Pierwsze zapytanie o listę najnowszych 20 napiwków
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import WithData from 'containers/WithData';
import ListView from 'components/ListView';

import http from 'utils/http';
import formatDateFrom from 'utils/formatDateFrom';
import sockets from 'utils/sockets';

class LatestDonates extends ListView {
  componentDidMount() {
    this.sockets = sockets(this.handleRecieveTipSocket, this.handleRecieveTemplateSocket);

    this.sockets.on();

    this.handleRecieveTemplateSocket();
  }

  /**
   * Funkcja odbierająca wiadomość z socketa i umieszczająca go do stanu widoku
   *
   * @param {Object} message
   * @returns {void}
   */
  handleRecieveTipSocket = message => {
    this.setState(prevState => ({
      items: [message, ...prevState.items],
    }));
  };

  /**
   * Funkcja wykonująca się gdy socket z szablonem zostanie odebrany.
   *
   * @param {Object} message
   * @returns {void}
   */
  handleRecieveTemplateSocket = async () => {
    const { match } = this.props;
    const { currentTemplate } = this.state;

    const { showDonatesFromDate, numberDisplayedItems } = currentTemplate.config;
    const dateFrom = formatDateFrom(showDonatesFromDate, '&from=');

    let goalId = '';
    if (showDonatesFromDate.type === 'FROM_GOAL') {
      goalId = `&goalId=${encodeURIComponent(showDonatesFromDate.goal)}`;
    }

    try {
      const { data: tips } = await http.get(
        `/widget/last-tips/${match.params.userId}?limit=${numberDisplayedItems}${dateFrom}${goalId}`,
      );

      this.setState({
        items: tips,
      });
    } catch (error) {
      Sentry.setExtra('error', error);
      Sentry.captureException(error);
    }
  };
}

export default withRouter(WithData(LatestDonates));
