import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { fetchWidgetMessageStatus } from 'store/actions/widgetmessage';
import { fetchConfigurators } from 'store/actions/configurations';
import { fetchTemplates } from 'store/actions/templates';

import 'react-toastify/dist/ReactToastify.css';
import 'normalize.css';

import ErrorMessage from 'components/ErrorMessage';

import GlobalStyles from 'styles/Global';

import { subscribe } from 'utils/emitter';
import http from 'utils/http';
import isPreviewMode from 'utils/isPreviewMode';
import loadWebfontsFromTemplates from 'utils/loadWebfontsFromTemplates';
import fetchUsedMediaInTipAlertView from 'utils/fetchUsedMediaInTipAlertView';

import store from './store';

import GoalVoting from './pages/GoalVoting';
import TipAlert from './pages/TipAlert';
import TipsGoal from './pages/TipsGoal';
import CounterToEndLive from './pages/CounterToEndLive';
import LatestDonates from './pages/LatestDonates';
import LargestDonates from './pages/LargestDonates';

import { COUNTER_TO_END_LIVE } from './types/widgets';

class App extends Component {
  state = {
    isError: false,
    isLoading: true,
  };

  constructor(props) {
    super(props);

    const [widgetType, userId, templateId] = new URL(window.location.href).pathname
      .split('/')
      .filter(chunk => !!chunk);

    switch (widgetType) {
      case 'LATEST_MESSAGES':
        this.configurationType = 'LATEST_DONATES';
        break;
      case 'LARGEST_MESSAGES':
        this.configurationType = 'LARGEST_DONATES';
        break;
      default:
        this.configurationType = widgetType;
    }

    this.userId = userId;
    this.templateId = templateId;
  }

  componentDidMount() {
    this.loadData();

    subscribe('FETCH_MEDIA', () => {
      this.fetchUsedMedia();
    });
  }

  loadData = async () => {
    try {
      const [, templates] = await Promise.all([
        store.dispatch(fetchConfigurators(this.configurationType, this.userId)),
        store.dispatch(fetchTemplates(this.configurationType, this.userId)),
        store.dispatch(fetchWidgetMessageStatus(this.userId)),
      ]);
      loadWebfontsFromTemplates(templates);

      if (
        this.configurationType === 'TIP_ALERT' ||
        this.configurationType === 'COUNTER_TO_END_LIVE'
      ) {
        await this.fetchUsedMedia();
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      Sentry.setExtra('error', error);
      Sentry.captureException(error);
      this.setState({
        isError: true,
      });
    }
  };

  async fetchUsedMedia() {
    await fetchUsedMediaInTipAlertView(this.configurationType, this.templateId);
  }

  render() {
    const { isError, isLoading } = this.state;

    const oldGoalWidgetUrlsHandler = ({ match, history }) => {
      const currentUserId = match.params.userId;
      http.get(`/widget/goal/${currentUserId}/widget`).then(data => {
        history.push(`/TIPS_GOAL/${currentUserId}/DEFAULT_TIPS_GOAL_1/${data.data.goalId}`);
      });
    };

    if (isError) {
      return <ErrorMessage />;
    }

    if (isLoading) {
      if (isPreviewMode()) {
        return null;
      }

      return <strong style={{ fontSize: 20 }}>Trwa ładowanie widoku...</strong>;
    }

    return (
      <>
        <Router>
          <Switch>
            <Route
              path="/TIP_ALERT/:userId"
              component={() => <TipAlert widgetType="TIP_ALERT" />}
            />
            <Route
              path="/TIPS_GOAL/:userId/:templateId/:goalId"
              component={() => <TipsGoal widgetType="TIPS_GOAL" />}
            />
            <Route path="/TIPS_GOAL/:userId/:templateId" render={oldGoalWidgetUrlsHandler} />
            <Route
              path={`/${COUNTER_TO_END_LIVE}/:userId/:templateId`}
              component={() => <CounterToEndLive widgetType={COUNTER_TO_END_LIVE} />}
            />
            <Route
              path="/LATEST_MESSAGES/:userId/:templateId"
              component={() => <LatestDonates widgetType="LATEST_DONATES" />}
            />
            <Route
              path="/LARGEST_MESSAGES/:userId/:templateId"
              component={() => <LargestDonates widgetType="LARGEST_DONATES" />}
            />
            <Route path="/GOAL_VOTING/:userId/:templateId" component={() => <GoalVoting />} />
          </Switch>
        </Router>

        <ToastContainer autoClose={5000} />
        <GlobalStyles userId={this.userId} />
      </>
    );
  }
}

export default App;
