import styled from 'styled-components';

import ColorSample from './ColorSample';

const Goal = styled.div`
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  font-size: 20.5px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;

  ${ColorSample} {
    background-color: ${props => props.bgColor};
  }
`;

export default Goal;
