import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import WithData from 'containers/WithData';

import extractElementStyles from 'utils/extractElementStyles';
import { emit } from 'utils/emitter';
import formatDateFrom from 'utils/formatDateFrom';
import getTemplate from 'utils/getTemplate';
import hop from 'utils/hop';
import http from 'utils/http';
import sockets from 'utils/sockets';

import CustomCode from 'components/CustomCode';
import ErrorMessage from 'components/ErrorMessage';
import { Text, Time, VisualObject } from 'components/elements';

import Scene from './styles/Scene';
import AnimatedPrice from '../TipsGoal/styles/AnimatedPrice';

class CounterToEndLive extends Component {
  static propTypes = {
    templates: PropTypes.instanceOf(Array).isRequired,
    configuration: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const { match, templates } = this.props;

    this.state = {
      loadedInitAmount: false,
      isError: false,
      currentTemplate: getTemplate(match.params.templateId, templates),
      time: 0,
      additionalTime: 0,
      showDays: false,
    };

    this.sockets = sockets(
      this.handleRecieveTipSocket,
      () => {
        emit('FETCH_MEDIA');
      },
      this.handleRecieveConfigurationSocket,
      () => null,
      () => null,
      this.handleReceiveTwitchTimeSocket,
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { match, templates } = props;

    return {
      currentTemplate: getTemplate(match.params.templateId, templates),
    };
  }

  componentDidMount() {
    this.sockets.on();
    this.getCountdownFromAPI();
  }

  componentWillUnmount() {
    this.sockets.off();
  }

  getCountdownFromAPI = async () => {
    const { match, configuration } = this.props;
    const {
      startDate,
      beginDate,
      priceFromAddTime,
      addTimeWithoutCommission,
      extraTime,
      showDays,
    } = configuration.config;

    const from = formatDateFrom({ type: 'FROM_DAY', value: beginDate }, '?from=');

    try {
      const { data } = await http.get(
        `/widget/countdown/${match.params.userId}${from}&threshold=${priceFromAddTime}`,
      );

      const { amount, commission, externally_added_time: externallyAdded } = data;

      const time = new Date(startDate);
      const finalAmount = addTimeWithoutCommission ? amount - commission || 0 : amount;
      const additionalTime = (parseInt(finalAmount, 10) / 100) * parseInt(extraTime, 10);

      this.setState({
        loadedInitAmount: true,
        time: new Date(time.setSeconds(time.getSeconds() + additionalTime + externallyAdded)),
        showDays,
      });
    } catch (error) {
      Sentry.setExtra('error', error);
      Sentry.captureException(error);
    }
  };

  /**
   * Funkcja odbierająca obiekt z socketa i umieszczająca go do store
   *
   * @param {Object} message
   * @returns {void}
   */
  handleRecieveTipSocket = message => {
    const { configuration } = this.props;

    if (message.resent) {
      return;
    }

    const price = configuration.config.addTimeWithoutCommission
      ? message.amount - message.commission || 0
      : message.amount;

    this.setState({
      tip: price,
    });

    this.calcTotaltime();
  };

  handleReceiveTwitchTimeSocket = message => {
    const { time } = JSON.parse(message);

    this.addTime(time);
  };

  /**
   * Funkcja uruchamiająca się po odebraniu obiektu konfiguracyjnego
   *
   * @returns {void}
   */
  handleRecieveConfigurationSocket = () => {
    this.getCountdownFromAPI();
  };

  /**
   * Funkcja wyszukująca szablon z pobranych szablonów
   *
   * @param {string} templateId
   * @returns {(Object|null)}
   */
  getTemplate = templateId => {
    const { templates } = this.props;

    return templates.find(template => template.id === templateId) || null;
  };

  /**
   * Funkcja odpalająca się po animacji wpłaconej kwoty
   *
   * @returns {void}
   */
  handleAnimationEnd = () => {
    this.setState({
      animateAdditionalTime: null,
    });
  };

  calcTotaltime = () => {
    const { configuration } = this.props;
    const { priceFromAddTime, extraTime } = configuration.config;
    const { tip } = this.state;

    if (tip && tip >= priceFromAddTime) {
      const additionalTime = (parseInt(tip, 10) / 100) * parseInt(extraTime, 10);
      this.addTime(additionalTime);
    } else {
      this.setState({
        additionalTime: 0,
      });
    }
  };

  addTime = amount => {
    const { time } = this.state;
    let current = new Date();
    current = current > time ? current : new Date(time);

    const additionalTimeFormat =
      Math.floor(amount / 60) === 0 ? `+${amount} sec` : `+${Math.floor(amount / 60)} min`;

    this.setState({
      time: new Date(current.setSeconds(current.getSeconds() + amount)),
      additionalTime: additionalTimeFormat,
      animateAdditionalTime: true,
    });
  };

  /**
   * Funkcja sprawdzajaca czy komponent ma wyrenderować zawartość sceny
   * Sprawdzane są przypadki czy został ustawiony odpowiedni szablon oraz
   * czy została pobrana początkowa kwota.
   *
   * @returns {bool}
   */
  shouldRender() {
    const { loadedInitAmount, currentTemplate } = this.state;

    return !!currentTemplate && loadedInitAmount;
  }

  render() {
    if (!this.shouldRender()) return null;
    const { isError, currentTemplate, time, additionalTime, animateAdditionalTime } = this.state;

    if (isError) {
      return <ErrorMessage />;
    }

    const {
      config: { elementsOptions },
    } = currentTemplate;

    const { showDays } = this.state;
    elementsOptions.textInput.showDays = showDays;

    return (
      <Scene>
        {hop(elementsOptions, 'visualObject') && (
          <VisualObject options={elementsOptions.visualObject} className="tpl-visual-object-1" />
        )}

        {hop(elementsOptions, 'text') && (
          <Text options={elementsOptions.text} className="tpl-text" />
        )}

        <Time
          withEndFireworks
          mode={currentTemplate.config.mode}
          options={elementsOptions.textInput}
          time={time}
        />

        {elementsOptions.additionalTime.isVisible && animateAdditionalTime && additionalTime && (
          <AnimatedPrice
            onAnimationEnd={this.handleAnimationEnd}
            style={{ ...extractElementStyles(elementsOptions.additionalTime) }}
            className="tpl-time-value"
          >
            <span>{additionalTime}</span>
          </AnimatedPrice>
        )}

        <CustomCode template={currentTemplate} />
      </Scene>
    );
  }
}

export default withRouter(WithData(CounterToEndLive));
