import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  color: #ffffff;
  text-shadow: 0 0 8px black;
  font-family: var(--google-sora-cdn);
`;

export default Wrapper;
