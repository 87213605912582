import DEFAULT_MEDIUM_IMAGE_1 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_1.png';
import DEFAULT_MEDIUM_IMAGE_2 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_2.gif';
import DEFAULT_MEDIUM_IMAGE_3 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_3.gif';
import DEFAULT_MEDIUM_IMAGE_4 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_4.gif';
import DEFAULT_MEDIUM_IMAGE_5 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_5.gif';
import DEFAULT_MEDIUM_VIDEO_1 from 'assets/defaultMedia/videos/DEFAULT_MEDIUM_VIDEO_1.webm';
import DEFAULT_MEDIUM_AUDIO_1 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_1.mp3';
import DEFAULT_MEDIUM_AUDIO_2 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_2.mp3';
import DEFAULT_MEDIUM_AUDIO_3 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_3.mp3';
import DEFAULT_MEDIUM_AUDIO_4 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_4.mp3';

export default {
  DEFAULT_MEDIUM_IMAGE_1: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_IMAGE_1,
      provider: 'sonata.media.provider.image',
    },
  },
  DEFAULT_MEDIUM_IMAGE_2: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_IMAGE_2,
      provider: 'sonata.media.provider.image',
    },
  },
  DEFAULT_MEDIUM_IMAGE_3: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_IMAGE_3,
      provider: 'sonata.media.provider.image',
    },
  },
  DEFAULT_MEDIUM_IMAGE_4: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_IMAGE_4,
      provider: 'sonata.media.provider.image',
    },
  },
  DEFAULT_MEDIUM_IMAGE_5: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_IMAGE_5,
      provider: 'sonata.media.provider.image',
    },
  },
  DEFAULT_MEDIUM_VIDEO_1: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_VIDEO_1,
      provider: 'sonata.media.provider.video',
    },
  },
  DEFAULT_MEDIUM_AUDIO_1: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_AUDIO_1,
      provider: 'sonata.media.provider.user_audio',
    },
  },
  DEFAULT_MEDIUM_AUDIO_2: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_AUDIO_2,
      provider: 'sonata.media.provider.user_audio',
    },
  },
  DEFAULT_MEDIUM_AUDIO_3: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_AUDIO_3,
      provider: 'sonata.media.provider.user_audio',
    },
  },
  DEFAULT_MEDIUM_AUDIO_4: {
    default: true,
    reference: {
      url: DEFAULT_MEDIUM_AUDIO_4,
      provider: 'sonata.media.provider.user_audio',
    },
  },
};
