export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_CONFIGURATIONS':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_CONFIGURATIONS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.receivedData,
      };
    case 'FAILURE_CONFIGURATIONS':
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case 'UPDATE_CONFIGURATION': {
      const { configurationType, updatedConfiguration } = action;

      const items = state.items.map(item => {
        if (item.type === configurationType) {
          return {
            type: configurationType,
            config: updatedConfiguration,
          };
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    default:
      return state;
  }
};
