import io from 'socket.io-client';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import store from 'store';
import { updateConfiguration } from 'store/actions/configurations';
import { updateTemplate, insertTemplate } from 'store/actions/templates';

import { emit } from './emitter';
import isPreviewMode from './isPreviewMode';

/**
 * Funkcja uruchamiająca wszystkie sockety dla napiwków, konfiguracji oraz szablonów.
 *
 * @param {function} tipCallback
 * @param {function} templateCallback
 * @param {function} configuratorCallback
 * @param {function} goalsCallback
 * @param {function} votingCallback
 * @param {function} countdownTimeCallback
 * @returns {object}
 */
export default function sockets(
  tipCallback = () => {},
  templateCallback = () => {},
  configuratorCallback = () => {},
  goalsCallback = () => {},
  votingCallback = () => {},
  countdownTimeCallback = () => {},
) {
  const [, userId] = new URL(window.location.href).pathname.split('/').filter(chunk => !!chunk);

  const instances = {
    tip: io(`${process.env.REACT_APP_SOCKET_URL}/tip/${userId}`),
    template: io(`${process.env.REACT_APP_SOCKET_URL}/template/${userId}`),
    configuration: io(`${process.env.REACT_APP_SOCKET_URL}/configuration/${userId}`),
    goals: io(`${process.env.REACT_APP_SOCKET_URL}/goals/${userId}`),
    voting: io(`${process.env.REACT_APP_SOCKET_URL}/voting/${userId}`),
    countdownTime: io(`${process.env.REACT_APP_SOCKET_URL}/countdown_time/${userId}`),
  };

  const fetchMediaIfNeeded = async (type, succesMessage, errorMessage) => {
    const s = store.getState();

    const showMessage = !s.widgetmessages.disabled[0] && !isPreviewMode();

    if (type === 'TIP_ALERT') {
      try {
        emit('FETCH_MEDIA');
        if (showMessage) {
          toast.info(succesMessage);
        }
      } catch (error) {
        Sentry.setExtra('error', error);
        Sentry.captureException(error);
        if (showMessage) {
          toast.info(errorMessage);
        }
      }
    } else if (showMessage) {
      toast.info(succesMessage);
    }
  };

  const templateCb = (type, data) => {
    switch (data.action) {
      case 'updated':
        store.dispatch(updateTemplate(data.id, data.config));
        fetchMediaIfNeeded(
          type,
          `Zaktualizowano szablon o nazwie "${data.config.title}"`,
          'Wystąpił błąd podczas aktualizowania szablonu',
        );
        break;
      case 'created': {
        store.dispatch(insertTemplate(data.template));
        fetchMediaIfNeeded(
          type,
          `Dodano szablon o nazwie "${data.template.config.title}"`,
          'Wystąpił błąd podczas dodawania szablonu',
        );
        break;
      }
      default:
    }

    templateCallback();
  };

  const configurationCb = (type, config) => {
    store.dispatch(updateConfiguration(type, config));
    fetchMediaIfNeeded(
      type,
      'Zaktualizowano konfigurację widoku.',
      'Wystąpił błąd podczas aktualizacji widoku.',
    );

    configuratorCallback();
  };

  return {
    on() {
      instances.tip.on('tip_consumed', tipCallback);
      instances.template.on('template', templateCb);
      instances.configuration.on('configuration', configurationCb);
      instances.goals.on('goals', goalsCallback);
      instances.voting.on('voting', votingCallback);
      instances.countdownTime.on('countdown_time', countdownTimeCallback);
    },
    off() {
      instances.tip.off('tip_consumed', tipCallback);
      instances.template.off('template', templateCb);
      instances.configuration.off('configuration', configurationCb);
      instances.goals.off('goals', goalsCallback);
      instances.voting.off('voting', votingCallback);
      instances.countdownTime.off('countdown_time', countdownTimeCallback);
    },
  };
}
