import styled from 'styled-components';

import Odometer from 'components/Odometer';

import TextNote from './TextNote';

const Wrapper = styled.div.attrs(props => {
  let textNodeAlign = 'left';
  if (props.align === 'center') {
    textNodeAlign = 'center';
  } else if (props.align === 'flex-end') {
    textNodeAlign = 'right';
  }

  return {
    textNodeAlign,
  };
})`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'flex-start'};
  order: ${props => (props.reverse ? 0 : 2)};
  justify-content: ${props => (props.reverse ? 'flex-end' : 'flex-start')};
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  font-size: 28.72px;

  ${TextNote} {
    text-align: ${props => props.textNodeAlign};
  }

  ${Odometer} {
    font-size: 20px;
    padding-top: 10px;
  }
`;

export default Wrapper;
