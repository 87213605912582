import { css, keyframes } from 'styled-components';

const pulseScale = 1.07;

const pulseAnimation = keyframes`
  0% {
    transform: scaleX(1);
  }
  
  50% {
    transform: scale3d(${pulseScale}, ${pulseScale}, ${pulseScale});
  }
  
  to {
    transform: scaleX(1);
  }
`;

const pulsing = css`
  animation-name: ${pulseAnimation};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
`;

export default pulsing;
