import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Odometer from './vendor/Odometer';
import './style/odometer.css';

export default class ReactOdometer extends PureComponent {
  static propTypes = {
    auto: PropTypes.bool,
    selector: PropTypes.string,
    format: PropTypes.string,
    duration: PropTypes.number,
    theme: PropTypes.string,
    animation: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  static defaultProps = {
    auto: false,
    selector: '.odometer',
    format: '(,ddd).dd',
    duration: 3000,
    theme: 'default',
    animation: true,
  };

  constructor(props) {
    super(props);

    this.node = React.createRef();
  }

  componentDidMount() {
    const { value, ...options } = this.props;

    this.odometer = new Odometer({
      el: this.node.current,
      value,
      ...options,
    });
  }

  componentDidUpdate() {
    const { value } = this.props;
    this.odometer.update(value);
  }

  render() {
    return React.createElement('div', {
      ref: this.node,
    });
  }
}
