import moment from 'moment';

export default function formatDateFrom(showDonatesFromDate, queryString) {
  let dateFrom = '';

  switch (showDonatesFromDate.type) {
    case 'LAST_DAY':
      dateFrom = moment()
        .startOf('day')
        .format();
      break;
    case 'LAST_WEEK':
      dateFrom = moment()
        .startOf('isoWeek')
        .format();
      break;
    case 'LAST_MONTH':
      dateFrom = moment()
        .startOf('month')
        .format();
      break;
    case 'LAST_YEAR':
      dateFrom = moment()
        .startOf('year')
        .format();
      break;
    case 'FROM_DAY':
      dateFrom = moment(showDonatesFromDate.value).format();
      break;
    default:
      dateFrom = '';
  }

  if (dateFrom) {
    return `${queryString}${encodeURIComponent(dateFrom)}`;
  }

  return encodeURIComponent(dateFrom);
}
