import React, { useContext, useEffect, useState } from 'react';

import Context from '../../../../context';
import { useVotingGoals } from '../hooks';

import BarWrapper from './components/BarWrapper';
import Goal from './components/Goal';

const Bar = () => {
  const { template, finished } = useContext(Context);
  const { goals, isBiggest } = useVotingGoals();
  const [allEmpty, setAllEmpty] = useState(false);

  useEffect(() => {
    if (goals.length > 0) {
      setAllEmpty(goals.filter(i => i.percentage > 0).length === 0);
    }
  }, [goals]);

  const {
    config: {
      options: { borderRadius },
    },
  } = template;

  return (
    <BarWrapper borderRadius={borderRadius} className="tpl-progress-bar">
      {goals.map(({ item, percentage }) => (
        <Goal
          key={item.goal.id}
          goal={item}
          percentage={allEmpty ? 100 / goals.length : percentage}
          isWinner={isBiggest(percentage)}
          finished={finished}
          isEmpty={allEmpty}
        />
      ))}
    </BarWrapper>
  );
};

export default Bar;
