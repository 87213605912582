/**
 * Funkcja wyciągająca wszystkie style dla elementu
 *
 * @param {Object} elementConfig
 * @returns {Object}
 */
export default function extractElementStyles(elementConfig) {
  if (!elementConfig && elementConfig.constructor !== Object) {
    throw new Error('Do funkcji nie został przekazany obiekt.');
  }

  const { styles, position, size } = elementConfig;

  let extractedStyles = {
    ...styles,
  };

  if (position) {
    extractedStyles = {
      ...extractedStyles,
      left: position.x,
      top: position.y,
    };
  }

  if (size) {
    extractedStyles = {
      ...extractedStyles,
      ...size,
    };
  }

  return extractedStyles;
}
