import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${props => props.align || 'flex-start'};
`;

export default Wrapper;
