import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';

import extractElementStyles from 'utils/extractElementStyles';

import ListItem from './components/ListItem';

const List = ({ template, items }) => {
  const [isInitialAnimation, setIsInitialAnimation] = useState(true);
  const [pulsingItem, setPulsingItem] = useState(null);

  useEffect(() => {
    if (pulsingItem !== null) {
      const resetPulsingTimeout = setTimeout(() => {
        setPulsingItem(null);
      }, 1500);

      return () => {
        clearTimeout(resetPulsingTimeout);
      };
    }

    return undefined;
  }, [pulsingItem]);

  const {
    config: {
      elementsOptions: { list },
    },
  } = template;

  const templateConfig = template.config;

  const renderList = () =>
    items.map((item, index) => (
      <ListItem
        index={index}
        key={item.id}
        itemId={item.id}
        amount={item.amount}
        commission={item.commission}
        nickname={item.nickname}
        settings={list.children}
        shouldPulsing={item.id === pulsingItem}
      />
    ));

  const onListAnimationFinish = animatedItems => {
    if (isInitialAnimation) {
      setIsInitialAnimation(false);
    } else if (animatedItems.length) {
      setPulsingItem(animatedItems[0].props.itemId);
    }
  };

  const shouldAnimateList = !templateConfig.animation.enable;
  const isVertical = templateConfig.displayDirection === 'vertical';

  const listContainerStyles = {
    display: 'grid',
    justifyContent: 'start',
    alignContent: 'start',
    gridGap: templateConfig.spacingBetweenElements,
    gridAutoFlow: isVertical ? 'row' : 'column',
  };

  if (isVertical) {
    listContainerStyles.gridTemplateColumns = '1fr';
  }

  if (shouldAnimateList) {
    const { styles, sizes } = list;

    return (
      <FlipMove
        appearAnimation
        enterAnimation
        staggerDelayBy={20}
        staggerDurationBy={20}
        onFinishAll={onListAnimationFinish}
        style={{
          ...extractElementStyles({
            ...styles,
            ...sizes,
          }),
          ...listContainerStyles,
        }}
      >
        {renderList()}
      </FlipMove>
    );
  }
  return (
    <div
      style={{
        ...extractElementStyles(list),
        ...listContainerStyles,
      }}
    >
      {renderList()}
    </div>
  );
};

List.propTypes = {
  template: PropTypes.instanceOf(Object).isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
};

export default List;
