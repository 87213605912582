import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--white-white);
  font-size: 16.67px;
  font-weight: bold;
  gap: 16px;

  svg {
    fill: currentColor;
    width: 20px;
  }
`;

export default Wrapper;
