const templates = [
  {
    title: 'Domyślny szablon #1',
    editable: false,
    animation: {
      in: 'slideInUp',
      out: 'fadeOut',
      during: '',
      duration: 1,
      displayDuration: 7,
      autoDisplayDuration: true,
    },
    elementsOptions: {
      price: {
        styles: {
          color: '#67ff00',
          zIndex: 2,
          fontSize: 55,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 211,
          y: 131,
        },
        animation: {
          in: '',
          out: '',
          during: 'heartBeat',
          duration: 1,
        },
        isVisible: true,
      },
      message: {
        size: {
          width: 507,
        },
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 146.5,
          y: 194,
        },
        animation: {
          in: '',
          out: '',
          during: 'pulse',
          duration: 1,
        },
        isVisible: true,
      },
      username: {
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 60,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: -154,
          y: 65,
        },
        animation: {
          in: '',
          out: '',
          during: 'rubberBand',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject1: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject2: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject3: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction: {
        value: 'Tekst:',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction1: {
        value: 'Tekst 1',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction2: {
        value: 'Tekst 2',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction3: {
        value: 'Tekst 3',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
    },
    amountWithoutCommission: false,
  },
  {
    title: 'Domyślny szablon #2',
    editable: false,
    animation: {
      in: 'slideInUp',
      out: 'fadeOut',
      during: '',
      duration: 1,
      displayDuration: 7,
      autoDisplayDuration: true,
    },
    elementsOptions: {
      price: {
        styles: {
          color: '#2790ff',
          zIndex: 2,
          fontSize: 55,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 211,
          y: 131,
        },
        animation: {
          in: '',
          out: '',
          during: 'rubberBand',
          duration: 1,
        },
        isVisible: true,
      },
      message: {
        size: {
          width: 507,
        },
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 146.5,
          y: 194,
        },
        animation: {
          in: '',
          out: '',
          during: 'pulse',
          duration: 1,
        },
        isVisible: true,
      },
      username: {
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 60,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: -154,
          y: 65,
        },
        animation: {
          in: '',
          out: '',
          during: 'heartBeat',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject1: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject2: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject3: {
        size: {
          width: 626,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_1',
        position: {
          x: 87,
          y: 33.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction: {
        value: 'Tekst:',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction1: {
        value: 'Tekst 1',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction2: {
        value: 'Tekst 2',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction3: {
        value: 'Tekst 3',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
    },
    amountWithoutCommission: false,
  },
  {
    title: 'Domyślny szablon #3',
    editable: false,
    animation: {
      in: 'slideInUp',
      out: 'fadeOut',
      during: '',
      duration: 1,
      displayDuration: 7,
      autoDisplayDuration: true,
    },
    elementsOptions: {
      price: {
        styles: {
          color: '#fffe00',
          zIndex: 2,
          fontSize: 55,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 211,
          y: 268.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'rubberBand',
          duration: 1,
        },
        isVisible: true,
      },
      message: {
        size: {
          width: 454,
        },
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 27,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 173,
          y: 335,
        },
        animation: {
          in: '',
          out: '',
          during: 'pulse',
          duration: 1,
        },
        isVisible: true,
      },
      username: {
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 60,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: -154,
          y: 200,
        },
        animation: {
          in: '',
          out: '',
          during: 'heartBeat',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject: {
        size: {
          width: 333,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_5',
        position: {
          x: 233.5,
          y: 12.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'wobble',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject1: {
        size: {
          width: 333,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_5',
        position: {
          x: 233.5,
          y: 12.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'wobble',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject2: {
        size: {
          width: 333,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_5',
        position: {
          x: 233.5,
          y: 12.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'wobble',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject3: {
        size: {
          width: 333,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_5',
        position: {
          x: 233.5,
          y: 12.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'wobble',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction: {
        value: 'Tekst:',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction1: {
        value: 'Tekst 1',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction2: {
        value: 'Tekst 2',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction3: {
        value: 'Tekst 3',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
    },
    amountWithoutCommission: false,
  },
  {
    title: 'Domyślny szablon #4',
    editable: false,
    animation: {
      in: 'slideInUp',
      out: 'fadeOut',
      during: '',
      duration: 1,
      displayDuration: 7,
      autoDisplayDuration: true,
    },
    elementsOptions: {
      price: {
        styles: {
          color: '#ff0000',
          zIndex: 2,
          fontSize: 55,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 211,
          y: 268.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'rubberBand',
          duration: 1,
        },
        isVisible: true,
      },
      message: {
        size: {
          width: 454,
        },
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 27,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 173,
          y: 335,
        },
        animation: {
          in: '',
          out: '',
          during: 'pulse',
          duration: 1,
        },
        isVisible: true,
      },
      username: {
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 60,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: -154,
          y: 200,
        },
        animation: {
          in: '',
          out: '',
          during: 'heartBeat',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject: {
        size: {
          width: 528,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_4',
        position: {
          x: 106.5,
          y: -86.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'swing',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject1: {
        size: {
          width: 528,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_4',
        position: {
          x: 106.5,
          y: -86.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'swing',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject2: {
        size: {
          width: 528,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_4',
        position: {
          x: 106.5,
          y: -86.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'swing',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject3: {
        size: {
          width: 528,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_4',
        position: {
          x: 106.5,
          y: -86.5,
        },
        animation: {
          in: '',
          out: '',
          during: 'swing',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction: {
        value: 'Tekst:',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction1: {
        value: 'Tekst 1',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction2: {
        value: 'Tekst 2',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction3: {
        value: 'Tekst 3',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 576,
          y: 61,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
    },
    amountWithoutCommission: false,
  },
  {
    title: 'Domyślny szablon #5',
    editable: false,
    animation: {
      in: 'slideInUp',
      out: 'slideOutDown',
      during: '',
      duration: 1,
      displayDuration: 7,
      autoDisplayDuration: true,
    },
    elementsOptions: {
      price: {
        styles: {
          color: '#9F00FF',
          zIndex: 2,
          fontSize: 55,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 211,
          y: 131,
        },
        animation: {
          in: '',
          out: '',
          during: 'heartBeat',
          duration: 1,
        },
        isVisible: true,
      },
      message: {
        size: {
          width: 507,
        },
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 146.5,
          y: 194,
        },
        animation: {
          in: '',
          out: '',
          during: 'pulse',
          duration: 1,
        },
        isVisible: true,
      },
      username: {
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 60,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: -154,
          y: 65,
        },
        animation: {
          in: '',
          out: '',
          during: 'rubberBand',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject: {
        size: {
          width: 738,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_VIDEO_1',
        position: {
          x: 31,
          y: 20.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: true,
      },
      visualObject1: {
        size: {
          width: 738,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_VIDEO_1',
        position: {
          x: 31,
          y: 20.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject2: {
        size: {
          width: 738,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_VIDEO_1',
        position: {
          x: 31,
          y: 20.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      visualObject3: {
        size: {
          width: 738,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_VIDEO_1',
        position: {
          x: 31,
          y: 20.5,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction: {
        value: 'Tekst:',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 562,
          y: 71,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction1: {
        value: 'Tekst 1',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 562,
          y: 71,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction2: {
        value: 'Tekst 2',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 562,
          y: 71,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
      usernameAction3: {
        value: 'Tekst 3',
        styles: {
          color: '#fff',
          zIndex: 4,
          fontSize: 29,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 562,
          y: 71,
        },
        animation: {
          in: '',
          out: '',
          during: '',
          duration: 1,
        },
        isVisible: false,
      },
    },
    amountWithoutCommission: false,
  },
];

export default templates;
