import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as widgetMessagesActions from 'store/actions/widgetmessage';
import * as configurationsActions from 'store/actions/configurations';
import * as templatesActions from 'store/actions/templates';

const WithData = Component => props => <Component {...props} />;

const mapStateToProps = ({ configurations, templates, media, widgetmessages }, { widgetType }) => ({
  configuration: configurations.items.find(item => item.type === widgetType) || {},
  templates: templates.items.filter(item => item.type === widgetType),
  media: media.items,
  widgetmessage: widgetmessages.widgetmessage,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(configurationsActions, dispatch),
  ...bindActionCreators(templatesActions, dispatch),
  ...bindActionCreators(widgetMessagesActions, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), WithData);
