// TODO: Pierwsze zapytanie o listę najnowszych 20 napiwków

/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marquee } from '@tipply/marquee';

import { MARQUEE_MAX_SPEED, MARQUEE_MIN_SPEED } from 'store/constants';
import getTemplate from 'utils/getTemplate';
import CustomCode from 'components/CustomCode';

import Scene from './styles/Scene';
import List from './components/List';

const Context = React.createContext();

class ListView extends Component {
  static propTypes = {
    templates: PropTypes.instanceOf(Array).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const { match, templates } = this.props;

    this.state = {
      items: [],
      currentTemplate: getTemplate(match.params.templateId, templates),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { match, templates } = props;

    return {
      currentTemplate: getTemplate(match.params.templateId, templates),
    };
  }

  componentWillUnmount() {
    this.sockets.off();
  }

  /**
   * Funkcja sprawdzajaca czy komponent ma wyrenderować zawartość sceny
   * Sprawdzane są przypadki czy został ustawiony odpowiedni szablon.
   *
   * @returns {boolean}
   */
  shouldRender() {
    const { items, currentTemplate } = this.state;

    if (this.shouldEnableMarquee()) {
      return !!currentTemplate && items.length > 0;
    }

    return !!currentTemplate;
  }

  shouldEnableMarquee() {
    const {
      currentTemplate: { config },
    } = this.state;

    return config.animation.enable;
  }

  renderList = items => {
    const { currentTemplate } = this.state;

    return (
      <List
        template={currentTemplate}
        items={items.slice(0, currentTemplate.config.numberDisplayedItems)}
      />
    );
  };

  render() {
    if (!this.shouldRender()) return null;
    const { items, currentTemplate } = this.state;
    const templateConfig = currentTemplate.config;
    const slicedItems = items.slice(0, templateConfig.numberDisplayedItems);
    const directionHeight = templateConfig.displayDirection === 'horizontal' ? '150px' : '100%';

    const duration =
      slicedItems.length *
      (MARQUEE_MAX_SPEED + MARQUEE_MIN_SPEED - templateConfig.animation.durationMultiply);

    return (
      <Context.Provider value={{ currentTemplate }}>
        <Scene>
          {this.shouldEnableMarquee() ? (
            <Marquee
              direction={templateConfig.displayDirection}
              duration={duration}
              gap={templateConfig.spacingBetweenElements}
              style={{ height: directionHeight }}
            >
              {this.renderList(slicedItems)}
            </Marquee>
          ) : (
            this.renderList(slicedItems)
          )}
          <CustomCode template={currentTemplate} />
        </Scene>
      </Context.Provider>
    );
  }
}

export const ContextConsumer = Context.Consumer;
export default ListView;
