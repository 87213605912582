import moment from 'moment';

export default function formatDateTo(showDonatesToDate, queryString) {
  let dateTo = '';

  switch (showDonatesToDate.type) {
    case 'LAST_DAY':
      dateTo = moment()
        .endOf('day')
        .format();
      break;
    case 'LAST_WEEK':
      dateTo = moment()
        .endOf('isoWeek')
        .format();
      break;
    case 'LAST_MONTH':
      dateTo = moment()
        .endOf('month')
        .format();
      break;
    case 'LAST_YEAR':
      dateTo = moment()
        .endOf('year')
        .format();
      break;
    default:
      dateTo = '';
  }

  if (dateTo) {
    return `${queryString}${encodeURIComponent(dateTo)}`;
  }

  return encodeURIComponent(dateTo);
}
