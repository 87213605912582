import axios from 'axios';

const proxyUrl = process.env.REACT_APP_PROXY_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const mediaUrl = process.env.REACT_APP_MEDIA_URL;
const authUrl = `${proxyUrl}/auth`;

const instance = axios.create({
  baseURL: `${apiUrl}/api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export { proxyUrl, apiUrl, mediaUrl, authUrl };
export default instance;
