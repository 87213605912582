import { withRouter } from 'react-router-dom';
import md5 from 'crypto-js/md5';
import * as Sentry from '@sentry/react';

import WithData from 'containers/WithData';
import ListView from 'components/ListView';

import http from 'utils/http';
import formatDateFrom from 'utils/formatDateFrom';
import formatDateTo from 'utils/formatDateTo';
import sockets from 'utils/sockets';

class LargestDonates extends ListView {
  componentDidMount() {
    this.sockets = sockets(this.handleRecieveTipSocket, this.handleRecieveTemplateSocket);

    this.sockets.on();
    this.handleRecieveTipSocket();
  }

  /**
   * Funkcja wykonująca się po odbiorze socketa i wysyłająca zapytanie po top donatorów
   *
   * @returns {void}
   */
  handleRecieveTipSocket = async () => {
    try {
      const { currentTemplate } = this.state;
      const {
        match: {
          params: { userId },
        },
      } = this.props;
      const { numberDisplayedItems, showDonatesFromDate } = currentTemplate.config;
      const dateFrom = formatDateFrom(showDonatesFromDate, '&from=');
      const dateTo = formatDateTo(showDonatesFromDate, '&to=');

      let goalId = '';
      if (showDonatesFromDate.type === 'FROM_GOAL') {
        goalId = `&goalId=${encodeURIComponent(showDonatesFromDate.goal)}`;
      }

      const { data } = await http.get(
        `/widget/top-donators/${userId}?limit=${numberDisplayedItems}${dateFrom}${dateTo}&withoutCommission=${+currentTemplate
          .config.amountWithoutCommission}${goalId}`,
      );

      // Generujemy hashe na podstawie nickname wpłacającego
      const updatedData = data.map(item => ({
        ...item,
        id: md5(item.nickname).toString(),
      }));

      this.setState({
        items: updatedData,
      });
    } catch (error) {
      Sentry.setExtra('error', error);
      Sentry.captureException(error);
    }
  };

  /**
   * Funkcja odbierająca wiadomość z socketa i umieszczająca go do stanu widoku
   *
   * @param {Object} message
   * @returns {void}
   */
  handleRecieveTemplateSocket = async () => {
    this.handleRecieveTipSocket();
  };
}

export default withRouter(WithData(LargestDonates));
