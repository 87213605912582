import styled from 'styled-components';

import ParentScene from 'styles/Scene';

const Scene = styled(ParentScene)`
  padding: 15px;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
`;

export default Scene;
