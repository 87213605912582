import { createGlobalStyle } from 'styled-components';

import fontFaces from './base/fontFaces';
import userFontFaces from './base/userFontFaces';

export default createGlobalStyle`
  body {
    overflow: hidden;
  }

  :root {
    --google-sora-cdn: 'Sora', sans-serif;
  }
  
  ${fontFaces};
  ${userFontFaces};
`;
