import React, { useContext } from 'react';
import FlipMove from 'react-flip-move';

import CountUpNumber from 'components/CountUpNumber';

import Context from '../../../../context';

import { useVotingGoals } from '../hooks';

import InnerWrapper from './components/InnerWrapper';
import Wrapper from './components/Wrapper';
import ColorSample from './components/ColorSample';
import Goal from './components/Goal';

const Legend = () => {
  const { template } = useContext(Context);
  const { sortedGoals } = useVotingGoals();

  const {
    config: {
      options: { showGoals, align, reverse, showLegend, showPercents, legendAlign },
    },
  } = template;

  return (
    <Wrapper align={align} reverse={reverse} className="tpl-legend">
      {showLegend && (
        <FlipMove appearAnimation enterAnimation staggerDelayBy={20} staggerDurationBy={20}>
          <InnerWrapper align={legendAlign}>
            {sortedGoals.map(({ item: { goal: { title, id }, color }, percentage }) => (
              <Goal key={id} bgColor={color} className="tpl-legend-goal">
                <ColorSample className="tpl-legend-goal-color" />
                {showGoals && title}
                {showPercents && (
                  <>
                    <CountUpNumber
                      key="forNormal"
                      end={percentage}
                      decimals={2}
                      decimal=","
                      prefix="("
                      suffix="%)"
                      style={{
                        opacity: 0.5,
                        fontSize: 16,
                        alignSelf: 'flex-start',
                      }}
                    />
                  </>
                )}
              </Goal>
            ))}
          </InnerWrapper>
        </FlipMove>
      )}
    </Wrapper>
  );
};

export default Legend;
