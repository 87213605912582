/* eslint-disable import/prefer-default-export */
import http from 'utils/http';

const requestWidgetMessageStatus = () => ({
  type: 'REQUEST_WIDGET_MESSAGE',
});

const receiveWidgetMessageStatus = receivedData => ({
  type: 'RECEIVE_WIDGET_MESSAGE',
  receivedData,
});

export const fetchWidgetMessageStatus = userId => async dispatch => {
  dispatch(requestWidgetMessageStatus());
  try {
    const { data: widgetMessage } = await http.get(`/widgetmessage/${userId}`);

    dispatch(receiveWidgetMessageStatus([widgetMessage]));
    return Promise.resolve([widgetMessage]);
  } catch (error) {
    return Promise.reject(error);
  }
};
