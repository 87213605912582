export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_TEMPLATES':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_TEMPLATES':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.receivedData,
      };
    case 'FAILURE_TEMPLATES':
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case 'INSERT_TEMPLATE': {
      const items = [...state.items, action.template];

      return {
        ...state,
        items,
      };
    }
    case 'UPDATE_TEMPLATE': {
      const { templateId, updatedTemplate } = action;

      const items = state.items.map(item => {
        if (item.id === templateId) {
          return {
            ...item,
            config: updatedTemplate,
          };
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    default:
      return state;
  }
};
