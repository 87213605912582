import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

const CustomCode = ({ template }) => {
  const { config } = template;
  const { customCode = {} } = config;
  const { css, html, javascript } = customCode;

  useEffect(() => {
    if (javascript) {
      // eslint-disable-next-line no-eval
      eval(`${javascript}`);
    }
  }, [javascript]);

  const CustomStyleComponent = createGlobalStyle`${css}`;

  return (
    <>
      {css && <CustomStyleComponent />}
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </>
  );
};

CustomCode.propTypes = {
  template: PropTypes.instanceOf(Object).isRequired,
};

export default CustomCode;
