import styled from 'styled-components';

import calculateBorderRadius from 'utils/calculateBorderRadius';

const BarWrapper = styled.div`
  display: flex;
  border-radius: ${props => calculateBorderRadius(props.borderRadius, 76)}px;
  height: 76px;
  overflow: hidden;
  order: 1;
`;

export default BarWrapper;
