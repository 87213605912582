import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as WarningIcon } from './assets/warning.svg';

import Wrapper from './components/Wrapper';

const ScreenError = ({ message }) => (
  <Wrapper className="tpl-warning-message">
    <WarningIcon />
    {message}
  </Wrapper>
);

ScreenError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ScreenError;
