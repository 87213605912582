import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGTM from 'react-gtm-module';
import * as Sentry from '@sentry/react';

import App from './App';
import store from './store';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
  });

  ReactGTM.initialize({
    gtmId: 'GTM-P3VM787',
  });
}

// eslint-disable-next-line no-console
console.log(`Build: ${process.env.REACT_APP_BUILD_HASH}`);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
