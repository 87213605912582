import { useContext, useEffect, useState } from 'react';

import Context from '../../../context';

export const useVotingGoals = () => {
  const { goals } = useContext(Context);
  const [parts, setParts] = useState([]);

  const goalPercentage = ({ goal, stats }) => {
    if (stats) {
      const total = goals.reduce((previousValue, currentValue) => {
        const { stats: valueStats } = currentValue;

        if (!valueStats) {
          return previousValue;
        }

        return (
          previousValue +
          valueStats.amount -
          valueStats.commission +
          currentValue.goal.initial_value
        );
      }, 0);

      if (total === 0) {
        return 0;
      }

      return ((stats.amount - stats.commission + goal.initial_value) * 100) / total;
    }

    return 0;
  };

  const isBiggest = number => Math.max(...parts.map(p => p.percentage)) <= number;

  useEffect(() => {
    const items = [];
    goals.forEach(item => {
      items.push({
        item,
        percentage: goalPercentage(item),
      });
    });
    setParts(items);
  }, [goals]);

  return {
    goals: parts,
    sortedGoals: parts
      .slice()
      .sort((a, b) => a.percentage - b.percentage)
      .reverse(),
    isBiggest,
  };
};

export const x = () => {};
