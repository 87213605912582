/* eslint-disable import/prefer-default-export */
import http from 'utils/http';

const requestConfigurations = () => ({
  type: 'REQUEST_CONFIGURATIONS',
});

const receiveConfigurations = receivedData => ({
  type: 'RECEIVE_CONFIGURATIONS',
  receivedData,
});

const failureConfigurations = () => ({
  type: 'FAILURE_CONFIGURATIONS',
});

export const updateConfiguration = (configurationType, updatedConfiguration) => ({
  type: 'UPDATE_CONFIGURATION',
  configurationType,
  updatedConfiguration,
});

export const fetchConfigurators = (configurationType, userId) => async dispatch => {
  dispatch(requestConfigurations());

  try {
    const { data: configurationsData } = await http.get(
      `/configuration/${configurationType}/${userId}`,
    );

    dispatch(receiveConfigurations([configurationsData]));
    return Promise.resolve([configurationsData]);
  } catch (error) {
    dispatch(failureConfigurations());
    return Promise.reject(error);
  }
};
