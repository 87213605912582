import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: red;
  padding: 1em;
  font-family: 'Open Sans', sans-serif;

  .title {
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .info {
    font-size: 1.1em;

    p {
      margin: 0 0 1em;
    }
  }
`;

const ErrorMessage = ({ message, defaultError }) => (
  <Wrapper>
    <div className="title">
      {`${message} `}
      {defaultError && (
        <img
          src="https://cdn.7tv.app/emote/63c8a6c330027778647b3de8/1x.gif"
          alt=""
          style={{ verticalAlign: 'middle' }}
        />
      )}
    </div>
    {defaultError && (
      <div className="info">
        <p>
          Odśwież widżet w OBS by mógł znów zacząć działać prawidłowo. Jeśli to nie pomoże, odśwież
          pamięć podręczną przeglądarki w OBS.
        </p>
        <p>
          {'Nadal błąd? Napisz do nas na '}
          <u>fb.com/tipplypl</u>
        </p>
      </div>
    )}
  </Wrapper>
);

ErrorMessage.propTypes = {
  message: PropTypes.string,
  defaultError: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  message: 'Strona wymaga odświeżenia',
  defaultError: true,
};

export default ErrorMessage;
