import styled from 'styled-components';

const ColorSample = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 8.75px;
  transform: translateY(-2px);
`;

export default ColorSample;
