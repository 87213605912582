import styled from 'styled-components';

import ParentScene from 'styles/Scene';

const Scene = styled(ParentScene)`
  width: 800px;
  height: 600px;

  > div,
  > span {
    display: inline-block;
    position: absolute;
  }

  .elements-group {
    > span {
      &:not(:first-child) {
        margin: 0 0 0 8px;
      }
    }
  }
`;

export default Scene;
