import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CountUpNumber from 'components/CountUpNumber';
import extractElementStyles from 'utils/extractElementStyles';
import pulsing from 'styles/base/animations';

import { ContextConsumer } from '../../../index';

const ListItemWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  ${props => props.pulsing && pulsing}
  justify-content: ${props => props.align};

  > span {
    display: inline-block;

    &[data-element='colon'] {
      margin: 0 10px 0 0;
    }

    &[data-element='counter'] {
      margin: 0 5px 0 0;
    }
  }
`;

const ListItem = forwardRef(
  ({ amount, commission, nickname, index, settings, shouldPulsing = false }, ref) => (
    <ContextConsumer>
      {({ currentTemplate }) => {
        const {
          amountWithoutCommission,
          displayDirection,
          elementsOptions: {
            list: { align },
          },
        } = currentTemplate.config;
        const price = amountWithoutCommission ? amount - commission : amount;
        const listAlign = displayDirection === 'vertical' ? align : 'flex-start';

        return (
          <ListItemWrapper
            className="single-element"
            ref={ref}
            pulsing={shouldPulsing}
            align={listAlign}
          >
            {settings.counter.isVisible && (
              <span
                data-element="counter"
                style={{ ...extractElementStyles(settings.counter) }}
                className="tpl-counter"
              >
                {`${index + 1}.`}
              </span>
            )}

            {settings.nickname.isVisible && (
              <span
                data-element="nickname"
                style={{ ...extractElementStyles(settings.nickname) }}
                className="tpl-nickname"
              >
                {nickname}
              </span>
            )}
            {settings.colon.isVisible && (
              <span
                data-element="colon"
                style={{ ...extractElementStyles(settings.colon) }}
                className="tpl-colon"
              >
                :
              </span>
            )}
            {settings.price.isVisible && (
              <span
                data-element="price"
                style={{ ...extractElementStyles(settings.price) }}
                className="tpl-price"
              >
                <CountUpNumber
                  end={price / 100}
                  decimals={2}
                  decimal=","
                  separator=" "
                  suffix=" zł"
                />
              </span>
            )}
          </ListItemWrapper>
        );
      }}
    </ContextConsumer>
  ),
);

ListItem.propTypes = {
  index: PropTypes.number.isRequired,
  nickname: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  commission: PropTypes.number.isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
  shouldPulsing: PropTypes.bool,
};

ListItem.defaultProps = {
  shouldPulsing: false,
};

export default ListItem;
