/* eslint-disable import/prefer-default-export */
import http from 'utils/http';

import defaultMedia from '../data/defaultMedia';

const requestMedia = () => ({
  type: 'REQUEST_MEDIA',
});

const receiveMedia = receivedData => ({
  type: 'RECEIVE_MEDIA',
  receivedData,
});

const failureMedia = () => ({
  type: 'FAILURE_MEDIA',
});

export const fetchMedia = mediaIds => async (dispatch, getState) => {
  dispatch(requestMedia());

  try {
    const filteredIds = mediaIds.filter(item => !String(item).startsWith('DEFAULT_'));
    let media = {
      ...defaultMedia,
      ...getState().media.items,
    };

    if (filteredIds.length) {
      const { data: mediaData } = await http.get(
        `/media/formats?ids=${JSON.stringify(filteredIds)}`,
      );
      media = {
        ...media,
        ...mediaData,
      };
    }

    Object.filterOnlyUsed = obj =>
      Object.assign(
        ...Object.keys(obj)
          .filter(key => mediaIds.toString().includes(key))
          .map(key => ({ [key]: obj[key] })),
      );

    const onlyUsedMedia = Object.filterOnlyUsed(media);

    dispatch(receiveMedia(onlyUsedMedia));
    return Promise.resolve(onlyUsedMedia);
  } catch (error) {
    dispatch(failureMedia());
    return Promise.reject(error);
  }
};
