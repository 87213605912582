import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchConfigurators } from 'store/actions/configurations';
import sockets from 'utils/sockets';
import CustomCode from 'components/CustomCode';

import Scene from './components/Scene';
import ScreenElements from './components/ScreenElements';

import Context from './context';

const GoalVoting = ({ match }) => {
  const votingData = useSelector(state => state.configurations.items.slice().pop());
  const templates = useSelector(state => state.templates.items);
  const dispatch = useDispatch();
  const [currentTemplate, setCurrentTemplate] = useState();

  const { finishDate, goals } = votingData;

  const isFinished = () => (finishDate ? new Date() >= new Date(finishDate) : false);

  const [finished, setFinished] = useState(isFinished());

  useEffect(() => {
    const checkIsFinishInterval = setInterval(() => {
      setFinished(isFinished());
    }, 1000);

    return () => {
      clearInterval(checkIsFinishInterval);
    };
  }, [finishDate]);

  useEffect(() => {
    const isGoalInVoting = (goalId = null) => {
      if (goalId === null) {
        return true;
      }

      return goals.filter(i => i.goal.id === goalId).length > 0;
    };

    const refresh = (goalId = null) => {
      setTimeout(() => {
        if (isGoalInVoting(goalId)) {
          dispatch(fetchConfigurators('GOAL_VOTING', match.params.userId));
        }
      }, 500);
    };

    const instances = sockets(
      tip => {
        const { goal_id: goalId } = tip;
        refresh(goalId);
      },
      () => {},
      () => {},
      () => refresh(),
      () => refresh(),
    );

    instances.on();

    return () => {
      instances.off();
    };
  }, []);

  useEffect(() => {
    setCurrentTemplate(templates.find(i => i.id === match.params.templateId));
  }, [templates, match.params]);

  if (!votingData || !currentTemplate) return null;

  return (
    <Scene>
      <Context.Provider
        value={{
          template: currentTemplate,
          goals,
          finishDate,
          finished,
        }}
      >
        <ScreenElements finishDate={finishDate} goals={goals} template={currentTemplate} />
      </Context.Provider>
      <CustomCode
        template={{
          config: {
            ...currentTemplate.config.options,
          },
        }}
      />
    </Scene>
  );
};

GoalVoting.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      templateId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(GoalVoting);
