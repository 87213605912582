import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as tipsMessagesActions from 'store/actions/tipsMessages';

import TipsGoal from './TipsGoal';

const mapStateToProps = ({ tipsMessages: messages }) => ({ messages });

const mapDispatchToProps = dispatch => bindActionCreators(tipsMessagesActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TipsGoal);
