import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.reverse ? 'flex-start' : 'flex-end')};
  align-items: ${props => props.align || 'flex-start'};
  order: ${props => (props.reverse ? 2 : 0)};
`;

export default Wrapper;
