/* eslint-disable import/prefer-default-export */
import http from 'utils/http';

import defaultTemplates from '../data/defaultTemplates';

const requestTemplates = () => ({
  type: 'REQUEST_TEMPLATES',
});

const receiveTemplates = receivedData => ({
  type: 'RECEIVE_TEMPLATES',
  receivedData,
});

const failureTemplates = () => ({
  type: 'FAILURE_TEMPLATES',
});

export const insertTemplate = template => ({
  type: 'INSERT_TEMPLATE',
  template,
});

export const updateTemplate = (templateId, updatedTemplate) => ({
  type: 'UPDATE_TEMPLATE',
  templateId,
  updatedTemplate,
});

export const fetchTemplates = (templateType, userId) => async dispatch => {
  dispatch(requestTemplates());

  try {
    const { data: templatesData } = await http.get(`/templates/${templateType}/${userId}`);

    const templates = [
      ...defaultTemplates.filter(template => template.type === templateType),
      ...templatesData,
    ];

    dispatch(receiveTemplates(templates));
    return Promise.resolve(templatesData);
  } catch (error) {
    dispatch(failureTemplates());
    return Promise.reject(error);
  }
};
