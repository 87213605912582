import styled, { css } from 'styled-components';

export const elementShadow = css`
  display: block;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  height: 0;
`;

const Element = styled.span`
  display: inline-block;
  ${props => props.shadow && elementShadow}

  > div > img {
    margin: 5px 0px 5px 0px;
    height: 1.2em;
    width: auto;
    vertical-align: -0.3em;
  }
`;

export default Element;
