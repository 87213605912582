/**
 * Funkcja wyszukująca szablon o danym ID
 *
 * @param {string} templateId
 * @param {Array} templates
 * @returns {(Object|null)}
 */

export default function getTemplate(templateId, templates) {
  return templates.find(template => template.id === templateId) || null;
}
