export default (state = [], action) => {
  switch (action.type) {
    case 'INSERT_MESSAGE':
      return [...state, { message: action.message, ack: action.ack }];
    case 'CLEAR_LATEST_MESSAGE': {
      return [...state].slice(1);
    }
    case 'DELETE_MESSAGE':
      return state.filter(message => message.message.id !== action.id);
    default:
      return state;
  }
};
